.OrganizationProfile-editorWrapper {
  margin-top: 20px;
  transition:
    padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  padding-left: 8px;
}

.rdw-editor-toolbar {
  border: none;
}
