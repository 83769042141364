@font-face {
  font-family: "Montserrat";
  src:
    local("Roboto Mono"),
    url(./fonts/RobotoMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat"),
    url(./fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  src:
    local("Nunito"),
    url(./fonts/Nunito-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, "Nunito", "Montserrat", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* background-color: whitesmoke; */
  /* height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* a:visited {
  color: inherit;
  text-decoration: inherit;
} */


.MuiButton-contained {
  box-shadow: none;
}